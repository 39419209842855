<template>
  
  <v-row justify="center">
    <v-dialog v-model="on" width="600px" persistent light :retain-focus="false">
      <template v-slot:activator="{ on }">
        <v-icon @click="help" >mdi-help</v-icon>
      </template>
      <v-card>
        <v-card-title>
          <span class="subtitle-1 font-weight-black">{{definition.title}}</span>
        </v-card-title>
        <v-card-text class="body-1 font-weight-light text-justify" >
          {{definition.text}}</v-card-text>
        <v-expansion-panels focusable>
          <v-expansion-panel>
            <v-expansion-panel-header>Las tres fases</v-expansion-panel-header>
            <v-expansion-panel-content class="body-2 font-weight-light text-justify">
              <ol>
                <li><strong>Escuchar:</strong> primero debes escuchar un poema o verso en inglés a la vez que lo lees, de esta manera irás mejorando tu oído, relacionando lo que escuchas con lo que lees.</li>
                <li><strong>Traducir:</strong> después mejorarás tu vocabulario haciendo un pequeño ejercicio de traducción de lo que has oído. </li>
               <li><strong>Escribir:</strong> por último volverás a escuchar lo anterior, sin subtítulos y tendrás que escribir lo que oyes, para demostrar que eres capaz de reconocerlo.</li>
              </ol>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>{{definition.titleSecondary}}</v-expansion-panel-header>
            <v-expansion-panel-content class="body-2 font-weight-light text-justify">
              {{definition.textSecondary}}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card-actions>
           <v-btn color="green darken-1" block @click="help">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

</template>

<script>

export default {
  name: 'help',
  props: ['definition'],
  data() {
    return {
      on: false,
      show: true,
      initValue: 0
    }
  },
  mounted: function(){
    if (this.$store.state[this.definition.key] > 0) {
      this.initValue = this.$store.state[this.definition.key]
      this.show = true
      this.help()
    } else {
      this.show = false
    }
  },
  methods: {
    help: function(){
      if (!this.on) {
        this.on = true
        this.$root.$emit('onHelp')
      } else {
        this.on = false
        this.$store.state[this.definition.key] = 0
        if (this.initValue !== this.$store.state[this.definition.key]) {
          this.initValue = this.$store.state[this.definition.key]
          let toSend = {}
          toSend[this.definition.key] = this.$store.state[this.definition.key]
          this.$http.patch(this.$store.state.urlApi+'settings', toSend,
            { headers: {
                Accept: 'application/json',
                Authorization: 'Bearer '+this.$store.state.accessToken
          }})
        }
        this.$root.$emit('offHelp')
      } 
    }
  }
}
</script>

<style>
 .radio-group-full-height .v-label {
     height: 100% !important
 }
</style>
