<template>
	<v-footer app >
		<v-row dense>
			<v-col sm="6" xs="12" class="d-flex align-center justify-center justify-sm-start justify-md-start justify-lg-start justify-xl-start">
				<span class="body-2">© {{ new Date().getFullYear() }} — poemsandlearn</span>
			</v-col>
			<v-col sm="6" xs="12">	
			</v-col>
		</v-row>
    </v-footer>
</template>

<script>

import legalPoems from './LegalPoems.vue'

export default {
  name: 'pl-footer',
  data() {
    return {
      dialog: false
    }
  },
  beforeMount(){
    if (this.$route.params && this.$route.params.type && this.$route.params.type === 'privacy-policy'){
      this.dialog = true
    }
  },
  components: {
	'legal-poems': legalPoems
  }
}
</script>
