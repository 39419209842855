<template>
	<v-app>
		<router-view/>
	</v-app>
</template>

<script>

export default {
  name: 'app',
  created () {
    this.$vuetify.theme.dark = true
    if (this.$store.state.currentRoute === '/study' || this.$store.state.currentRoute === '/play' || this.$store.state.currentRoute === '/study-basic' || this.$store.state.currentRoute === '/play-basic' || this.$store.state.currentRoute === '/play-vsbot'){
      this.$store.state.fromStart = false
    }
    if (this.$store.state.currentRoute === '/result-play'){
      this.$store.state.currentRoute = '/home'
    }
    this.$store.state.refreshHome = true
    if (this.$store.state.currentRoute !== '/' && this.$store.state.currentRoute !== '/start/login' && this.$store.state.currentRoute !== '/start/register' && this.$store.state.currentRoute !== '/start/privacy-policy'){
      this.$router.push(this.$store.state.currentRoute)
    }
  }
}
</script>