<template>

<v-layout justify-center>
  <v-dialog v-model="dialog" persistent max-width="600px" transition="dialog-bottom-transition">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" rounded block outlined color="white" class="mt-2">Ya tengo una cuenta</v-btn>
    </template>
    <v-card v-if="dialog">
      <v-card-title row justify-center>
        <h3 class="headline text-md-center">Iniciar sesión</h3>
      </v-card-title>
      <v-form ref="form">
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md4>
                <v-text-field label="Alias" :rules="[validateRequired, validateName,validateAlphanumeric]" v-model="email" @keyup.enter.native="login"></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field label="Password" :rules="[validateRequired, validatePassword]" type="password" v-model="password" @keyup.enter.native="login"></v-text-field>
              </v-flex>
              <v-flex xs12>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" text @click="dialog = false">Cancelar</v-btn>
        <v-btn color="success" :loading="loading" @click="login">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</v-layout>




</template>

<script>

import passwordRequest from '../requestUtils/PasswordRequest.vue'

export default {
  name: 'login',
  data() {
    return {
      email: '',
      password: '',
      dialog: false,
      loading: false
    }
  },
  components: {
    'password-request':passwordRequest
  },
  beforeMount(){
    if (this.$route.params && this.$route.params.type && this.$route.params.type === 'login'){
      this.dialog = true
    }
  },
  methods: {
    login: function () {
      let valid = this.$refs.form.validate();
      if (valid && !this.loading) {
        this.loading = true;
        this.$store.commit('recalculateCaptcha')
        this.$http.post(this.$store.state.urlApi+'auth/login', { 
              name: this.email 
          ,   password: this.password 
          }, { headers: {
              Accept: 'application/json',
              'Captcha-Authorization': this.$store.state.captchaTokenResult
        }}).then(response => {
          this.loading = false;
          this.email = '';
          this.password = '';
          this.$refs.form.reset();
          this.dialog = false;
          this.$store.state.accessToken = response.body.access_token;
          this.$store.state.refreshToken = response.body.refresh_token;
          this.$store.state.expireAt = new Date().getTime() / 1000 + response.body.expires_in
          this.$store.state.refreshExpireAt = new Date().getTime() / 1000 + response.body.refresh_expires_in
          this.$store.state.anotherTokenRemoving = response.body.another_token_removing
          this.$store.state.refreshHome = true;
          this.$store.state.currentRoute = '/home';
          this.$store.state.anonymous = false
          this.$router.push(this.$store.state.currentRoute);
        }, response => {
          this.loading = false
          this.dialog = false
          let text = response.status === 401 ? 'Nombre o contraseña incorrectos' : 'La plataforma no está disponible en este momento, inténtelo más tarde';
          this.$root.$emit('changeSnackbar', { show : true , text , timeout: 4000, type: 'warning' } )
        });
      } 
    },
    validateEmail: function(value){
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || 'e-mail incorrecto'
    },
    validateAlphanumeric: function(value) {
      return /^[a-zA-Z0-9]+$/.test(value) || 'No utilices espacios ni caracteres extraños';
    },
    validateName: function(value){
      return (typeof value !== 'undefined' && value.length >= 6 && value.length <= 10) || 'Longitud entre 6 y 10'
    },
    validateRequired: function(value){
      return !!value || 'obligatorio'
    },
    validatePassword: function(value){
      return (typeof value !== 'undefined' && value.length >= 6 && value.length <= 10) || 'Longitud entre 6 y 10'
    }
  }
}
</script>

<style>

</style>
