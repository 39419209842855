import Vue from 'vue'
import Router from 'vue-router'
import Start from './views/Start.vue'
import store from './store.js'

Vue.use(Router)

let router = new Router({
  routes: [
    {
      path: '/',
      name: 'start',
      component: Start
    },
    {
      path: '/start/:type',
      name: 'startscreen',
      component: Start
    },
    {
      path: '/register/:hash',
      name: 'register',
      component: () => import('./views/Password.vue')
    },
    {
      path: '/password/:hash',
      name: 'password',
      component: () => import('./views/Password.vue')
    },
    {
      path: '/delete/:hash',
      name: 'delete',
      component: () => import('./views/Delete.vue')
    },
    {
      path: '/check-request/:type',
      name: 'checkRequest',
      component: () => import('./views/CheckRequest.vue')
    },
    {
      path: '/try-again',
      name: 'tryAgain',
      component: () => import('./views/TryAgain.vue')
    },
    {
      path: '/refresh',
      name: 'refresh',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Refresh.vue')
    },
    {
      path: '/home',
      name: 'home',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Home.vue')
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('./views/Settings.vue')
    },
    {
      path: '/start-basic',
      name: 'start-basic',
      component: () => import('./views/StartBasic.vue')
    },
    {
      path: '/instructions',
      name: 'instructions',
      component: () => import('./views/Instructions.vue')
    },
    {
      path: '/study-basic',
      name: 'study-basic',
      component: () => import('./views/StudyBasic.vue')
    },
    {
      path: '/play-basic',
      name: 'play-basic',
      component: () => import('./views/PlayBasic.vue')
    },
    {
      path: '/study',
      name: 'study',
      component: () => import('./views/Study.vue')
    },
    {
      path: '/learn-words',
      name: 'learn-words',
      component: () => import('./views/LearnWords.vue')
    },
    {
      path: '/play',
      name: 'play',
      component: () => import('./views/Play.vue')
    },
    {
      path: '/result',
      name: 'result',
      component: () => import('./views/Result.vue')
    },
    {
      path: '/play-vsbot',
      name: 'play-vsbot',
      component: () => import('./views/PlayVsbot.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
    let redirect = to.path === store.state.currentRoute || to.name === 'startscreen'
    if (redirect){
      if (to.path !== '/'){
        window[store.state.storage].setItem('state'+window.location.pathname, JSON.stringify(store.state))
      }
      next(true)
    } else if(store.state.currentRoute === '/') {
      window.history.back()
      next(false)
    } else {
      next(false)
    }
    
})

export default router