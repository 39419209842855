<template>

<div>
    <v-app-bar
      app
      clipped-center
      id="bar-app-pl"
    >

    <v-row align="center">
      <v-col cols="1" class="flex-grow-10 flex-wrap flex-shrink-0" style="max-width: 100%;">
        <v-toolbar-title v-if="!$store.state.loading && title">{{titleValue}}</v-toolbar-title>
        <help v-if="help" :definition="help"/>
      </v-col>
      <v-col class="d-flex justify-space-around">
        <template v-if="step && !$store.state.loading">
          <v-stepper :value="step" style="width: 100%;background: transparent;box-shadow:0px 0px 0px 0px rgba(0,0,0,0);">
            <v-stepper-header>
              <v-stepper-step :complete="step !== 'Escuchar'" step="Escuchar"></v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="step === 'Escribir'" step="Traducir"></v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="false" step="Escribir"></v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </template>
      
        <template >
          <v-progress-circular
            v-show="$store.state.loading"
            pa-2
            elevation-24
            indeterminate
            color="purple"
          ></v-progress-circular>
          <div v-show="!$store.state.loading" v-if="progress" class="mt-1">
            <points/>
            <span class="ma-2"><progress-today/></span>
            <count-vsbot/>
          </div>
        </template>

      </v-col>
      

      <v-col cols="1" class="d-flex justify-end">
          <v-dialog v-if="close" v-model="dialog" persistent max-width="290">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" class="d-flex justify-end">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">Salir</v-card-title>
              <v-card-text>¿Deseas finalizar la prueba?</v-card-text>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="warning" text @click="dialog = false">No</v-btn>
                <v-btn color="success" @click="toHome()">Sí</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-if="closeActivation" v-model="dialogToStart" persistent max-width="290">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">Salir</v-card-title>
              <v-card-text>¿Deseas salir de aquí?</v-card-text>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="warning" text @click="dialogToStart = false">No</v-btn>
                <v-btn color="success" @click="toStart()">Sí</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
      
          <v-menu
            v-if="logout"
            right
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="toSettings()"
                v-if="$store.state.currentRoute !== '/settings'"
              >
                <v-list-item-title>Ajustes</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="toHome()"
                v-if="$store.state.currentRoute === '/settings'"
              >
                <v-list-item-title>Mis poemas</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="showLogout()"
              >
                <v-list-item-title>Cerrar sesión</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
      </v-col>
    
    </v-row>

      <template v-slot:extension v-if="progressValue || homeTabs">

        <v-flex xs3 sm2 md1 v-if="progressValue">
          <span class="title">{{progressValue}}</span>
        </v-flex>
        <v-flex xs9 sm10 md11 v-if="progressValue">
          <v-progress-linear
            :value="progressStatus"
            striped
            color="purple"
            height="10"
          ></v-progress-linear>
        </v-flex>

        <tabs v-if="homeTabs"/>

      </template>

    </v-app-bar>

    <logout ref="logout"/>

</div>

</template>

<script>

import logout from '../launchers/Logout.vue'
import progressToday from '../markers/ProgressToday.vue'
import countVsbot from '../markers/CountVsbot.vue'
import points from '../markers/Points.vue'
import help from '../modals/Help.vue'
import tabs from './Tabs.vue'

export default {
  name: 'bar',
  props: ['logout','progress','title','titleText','close','closeActivation','step','help','homeTabs'],
  data() {
    return {
      titleValue : 'poemsandlearn',
      dialog : false,
      dialogToStart : false,
      progressStatus : false,
      progressValue: ''
    }
  },
  components: {
    logout,
    'progress-today' : progressToday,
    'count-vsbot': countVsbot,
    points,
    help,
    tabs
  },
  mounted(){
    this.titleValue = this.titleText ? this.titleText : 'poemsandlearn'
  },
  methods: {
    showLogout: function(){
      this.$refs.logout.show()
    },
    toHome: function(){
      this.$store.state.result = false
      this.$store.state.step = false
      this.$store.state.currentRoute = '/home'
      this.$router.push(this.$store.state.currentRoute)
    },
    setProgress: function(status,total){
      status = parseInt(status)
      total = parseInt(total)
      if (status > total){
        status = total
      }
      this.progressStatus = parseInt(status * 100 / total)
      this.progressValue = status + ' / ' + total
      if (status == total) {
        this.$root.$emit('onFinishProgressBar')
      }
    },
    toSettings: function(){
      this.$store.state.currentRoute = '/settings'
      this.$router.push(this.$store.state.currentRoute)
    },
    toStart: function(){
      if (this.$store.state.toStartRequest) {
        this.$store.state.currentRoute = this.$store.state.toStartRequest
        this.$router.push(this.$store.state.currentRoute)
        return
      }
      this.$store.state.accessToken = ''
      this.$store.state.currentRoute = '/'
      this.$router.push('/')
    }
  }
}
</script>

<style>
  .v-stepper__step__step{
    font-size: 16px;
  }
</style>