<template>

<v-layout row justify-center>
<v-dialog v-model="dialog" persistent max-width="400px">
  <template v-slot:activator="{ on }">
    <v-btn color="primary" v-on="on" large >{{buttonName}}</v-btn>
  </template>
  <v-card v-if="dialog">
    <v-card-title row justify-center>
      <h3 class="headline text-md-center" v-show="!sended">{{dialogTittle}}</h3>
      <h3 class="headline text-md-center" v-show="sended">Revisa tu correo</h3>
    </v-card-title>
    <v-form ref="form">
      <v-card-text v-show="!sended">
        {{dialogBody}}
      </v-card-text>
      <v-card-text v-show="sended">
        Revisa la bandeja de entrada de tu correo, podrás modificar la contraseña desde el enlace que te hemos enviado
      </v-card-text>
      <v-card-text>
        <v-text-field label="Email" :rules="[validateRequired, validateEmail]" v-model="email" :readonly="!forgot || loading || sended"></v-text-field>
      </v-card-text>
    </v-form>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-show="!sended" color="warning" text @click="dialog = false">Cancelar</v-btn>
      <v-btn v-show="!sended" color="primary" :loading="loading" @click="passwordRequest">OK</v-btn>
      <v-btn v-show="sended" color="success" @click="dialog = false">Cerrar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

</v-layout>

</template>

<script>

export default {
  name: 'PasswordRequest',
  data() {
    return {
      loading: false,
      dialog: false,
      sended: false,
      email: '',
      dialogTittle: '',
      dialogBody: '',
      buttonName: ''
    }
  },
  props: ['forgot'],
  beforeMount(){
    if (this.forgot){
      this.buttonName = 'Recordar';
      this.dialogTittle = 'Recordar contraseña';
      this.dialogBody = 'Si deseas recordar tu contraseña escribe tu mail y pulsa OK, te enviaremos un correo para que modifiques la contraseña';
      this.email = '';
    } else {
      this.buttonName = 'Modificar';
      this.dialogTittle = 'Modificar contraseña';
      this.dialogBody = 'Si deseas modificar tu contraseña pulsa OK, te enviaremos un correo para que edites la contraseña';
      this.email = this.$store.state.userName;
    }
  },
  methods: {
    passwordRequest: function(){
      let valid = this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        this.$store.commit('recalculateCaptcha')
        this.$http.post(this.$store.state.urlApi+'auth/password/request', { 
            email: this.email
          }, { headers: {
              Accept: 'application/json',
              'Captcha-Authorization': this.$store.state.captchaTokenResult
        }}).then(function() {
          this.sended = true;
          this.loading = false;
          this.$store.state.currentRoute = '/check-request/password'
          this.$router.push('/check-request/password')
        }, response => {
          this.dialog = false;
          this.loading = false;
          let text = response.body.error === 'mail_does_not_exist' ? 'El email no existe, no hay ningún usuario registrado con ese email' : 'Ocurrió un error inesperado, inténtelo más tarde';
          let type = response.body.error === 'mail_does_not_exist' ? 'warning' : 'error';
          this.$root.$emit('changeSnackbar', { show : true , text, timeout: 4000, type } )
        });
      }
    },
    validateEmail: function(value){
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || 'e-mail incorrecto'
    },
    validateRequired: function(value){
      return !!value || 'obligatorio'
    }
  }
}
</script>

<style>

</style>
