<template>
  
<div>
      
  <v-snackbar
    v-model="snackbarEnable"
    :color="snackbarType"
    :timeout="snackbarTimeout"
    multi-line
    top
  >
    {{ snackbarText }}
    <v-btn
      text
      @click="snackbarEnable = false"
    >
      OK
    </v-btn>
  </v-snackbar>
  <v-alert
    :value="messageView"
    prominent
    type="info"
    class="text-center text-xs-center"
    >
      {{messageText}}
  </v-alert>

</div>

</template>

<script>

export default {
  name: 'Messagev2',
  data() {
    return {
      messageView : false,
      messageText : '',
      snackbarEnable : false,
      snackbarType : 'error',
      snackbarTimeout : 4000,
      snackbarText : ''
    }
  },
  mounted(){
    this.$store.state.snackbarEnable = false
    let that = this
    this.$root.$on('changeMessage',function(data){
      that.messageView = data.show
      that.messageText = data.text
    })
    this.$root.$on('changeSnackbar',function(data){
      that.snackbarEnable = data.show
      that.snackbarType = data.type
      that.snackbarTimeout = data.timeout
      that.snackbarText = data.text
    })
  },
  beforeDestroy: function(){
    this.$root.$off('changeMessage')
    this.$root.$off('changeSnackbar')
  }
};
</script>
