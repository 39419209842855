<template>
<span>
  <v-badge
    :color="color"
  >
    <template v-slot:badge>
      <span>{{count}}</span>
    </template>
    <v-icon :size="sizeIcon" :color="colorIcon" @click="showModal">mdi-school</v-icon>
  </v-badge>
</span>
</template>

<script>

export default {
  name: 'CountVsbot',
  data() {
    return {
      color : 'warning',
      colorIcon : 'warning',
      count : 0,
      sizeIcon : 35
    }
  },
  props: ['size'],
  beforeMount: function(){
    if (this.size === "big"){
      this.count = ''
      this.color = 'transparent'
      this.colorIcon = 'warning'
      this.sizeIcon = 80
    } else {
      this.count = typeof this.$store.state.countVsbot !== 'undefined' ? this.$store.state.countVsbot : 0
      this.color = typeof this.$store.state.colorVsbot  !== 'undefined' ? this.$store.state.colorVsbot : 'warning'
      this.colorIcon = this.color
    }
  },
  mounted: function(){
    let that = this
    this.$root.$on('countVsbot',function(data){
      that.notify(data)
    })
  },
  methods: {
    notify: function(data){
      this.color = data.block ?  'success' : 'warning'
      this.colorIcon = this.color
      this.count = data.count
      this.$store.state.countVsbot = this.count
      this.$store.state.colorVsbot = this.color
    },
    toSuccess: function(){
      this.colorIcon = 'success'
    },
    showModal: function(){
      let text = ''
      if (!this.$store.state.countVsbot){
        this.$store.state.countVsbot = 0
      }
      if (this.$store.state.countVsbot == 1){
        text += 'Tienes un punto de reto. '
      } else {
        text += 'Tienes ' + this.$store.state.countVsbot + ' puntos de reto. '
      }
      text +=  'Conseguirás un punto de reto por cada uno que superes. Necesitas puntos de reto para desbloquear y subir de nivel casi todos los poemas.'
      this.$root.$emit('showModal', { text , title : 'Puntos de reto' } )
    }
  },
  beforeDestroy: function(){
    this.$root.$off('countVsbot')
  }
}
</script>
