<template>
  
  <v-row justify="center">
    <v-dialog v-model="on" width="500px" persistent light :retain-focus="false">
      <v-card>
        <v-card-title>
          <span class="subtitle-1 font-weight-black">Modo privado</span>
        </v-card-title>
        <v-card-text class="body-1 font-weight-light text-justify" >
          Estás intentando acceder a un poemsandlearn de uso privado, nada va a funcionar si no introduces la clave. Si no conoces la clave usa el poemsandlearn público pulsando el botón de modo público.
        </v-card-text>
        <v-card-text class="body-1 font-weight-light text-justify" >
          <v-text-field
            label="Clave"
            v-model="key"
            :autofocus="true"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
            <v-flex xs6 class="pa-2">
              <v-btn color="warning" block :link="true" href="https://poemsandlearn.delavieja.com" >Modo público</v-btn>
            </v-flex>
            <v-flex xs6 class="pa-2">
              <v-btn color="green darken-1" block @click="addKey" :disabled="key.length === 0">Añadir clave</v-btn>
            </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

</template>

<script>

export default {
  name: 'private-modal',
  data() {
    return {
      on: false,
      title: '',
      text: '',
      key: ''
    }
  },
  mounted: function(){
    let that = this
    this.$root.$on('showPrivateModal',function(){
      that.on = true
    })
  },
  methods: {
    addKey: function() {
      this.$store.state.captchaToken = this.key
      this.on = false
    } 
  },
  beforeDestroy: function(){
    this.$root.$off('showPrivateModal')
  }
}
</script>

<style>

</style>
