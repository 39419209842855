<template>
  <v-progress-circular
    :size="sizeBar"
    :width="widthBar"
    :value="getPercentage()"
    color="yellow"
    @click="showModal"
  >
    {{$store.state.coins}}
  </v-progress-circular>
</template>

<script>

export default {
  name: 'points',
  props: ['size'],
  data() {
    return {
      sizeBar: 55,
      widthBar: 8
    }
  },
  mounted: function(){
    if (this.size === 'big'){
      this.sizeBar = 80
      this.widthBar = 12
    }
  },
  methods: {
    getPercentage: function(){
      let percentage = Math.floor(this.$store.state.coins * 100 / 1500);
      return percentage > 100 ? 100 : percentage;
    },
    showModal: function(){
      let text = 'Tienes ' + this.$store.state.coins + ' puntos. Puedes conseguir puntos acertando palabras en la fase "escribir" del nivel avanzado de cualquier poema. Necesitas puntos para desbloquear y subir de nivel los poemas.'
      this.$root.$emit('showModal', { text , title : 'Puntos' } )
    }
  }
}
</script>

<style>

</style>
