<template>

  <v-tabs
    fixed-tabs
    hide-slider
    color="success"
    v-scroll="onScroll"
    v-model="tab"
  >
    <v-tab @click="$vuetify.goTo('#tabs-current', options)">
      <!--<v-icon large>mdi-fire</v-icon>--><h5>Estudiando</h5>
    </v-tab>
    <v-tab @click="$vuetify.goTo('#tabs-todo', options)">
      <!--<v-icon large>mdi-book</v-icon>--><h5>Bloqueados</h5>
    </v-tab>
    <v-tab @click="$vuetify.goTo('#tabs-done', options)">
      <!--<v-icon large>mdi-school</v-icon>--><h5>Superados</h5>
    </v-tab>
  </v-tabs>

</div>

</template>

<script>

export default {
  name: 'tabs',
  data() {
    return {
      options : {
          duration: 500,
          offset: 20,
          easing: 'linear'
      },
      tabsDoneElement: false,
      tabsCurrentElement: false,
      tabsTodoElement: false,
      barAppPl: false,
      tab : 0
    }
  },
  mounted: function(){
    this.tabsDoneElement = document.getElementById('tabs-done')
    this.tabsCurrentElement = document.getElementById('tabs-current')
    this.tabsTodoElement = document.getElementById('tabs-todo')
    this.barAppPl = document.getElementById('bar-app-pl');
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const heightBarAppPl = this.barAppPl.offsetHeight
      const bottomTabsDoneElement = this.tabsDoneElement.getBoundingClientRect().bottom - heightBarAppPl - 20
      const bottomTabsCurrentElement = this.tabsCurrentElement.getBoundingClientRect().bottom - heightBarAppPl - 20
      const bottomTabsTodoElement = this.tabsTodoElement.getBoundingClientRect().bottom - heightBarAppPl - 20
      if (bottomTabsCurrentElement > 0){
        this.tab = 0
      } else if (bottomTabsTodoElement > 0){
        this.tab = 1
      } else {
        this.tab = 2
      }
    },
  }
}
</script>