import Vue from 'vue'
import Vuex from 'vuex'
import md5 from 'js-md5'

Vue.use(Vuex)

const typeStorage = 'sessionStorage' 

let storageState = window[typeStorage].getItem('state'+window.location.pathname)
let state
let currentRoute = '/'
let refreshToken = ''
if (storageState){
  storageState = JSON.parse(storageState)
  if (storageState.accessToken && new Date().getTime() / 1000 < storageState.expireAt) {
    state = storageState
  } else if(storageState.refreshToken && new Date().getTime() / 1000 < storageState.refreshExpireAt){
    state = storageState
    currentRoute = '/refresh'
    refreshToken = state.refreshToken
  }
}

if (!state || currentRoute === '/refresh') {
  state = {
    urlApi : 'https://poemsandlearn.delavieja.com/api/',
    tutorialStep: 42,
    tutorialStepTitle: 'Rain',
    tutorialStepAuthor: 'Robert Louis Stevenson',
    accessToken : '',
    loading: false,
    refreshHome : true,
    mainApp : true,
    errorRedirect: '/home',
    currentRoute : currentRoute,
    anonymous : true,
    snackbarEnable : false,
    messageView : false,
    captchaToken : 'asda0edsaf0sdf23poemsandsdfsflearnsi290s',
    fromStart : true,
    unknownWords : [],
    mainUnknownWord : false,
    idsUnknownWords : [],
    vuetify : 2,
    increaseWordsToday : 0,
    increaseWordsObjetive : 70,
    storage : typeStorage,
    refreshToken: refreshToken,
    coins: 0,
    messageToday: 'Días de racha.',
    messageTodaySecondary: '',
    objetiveStreak: false,
    stepsDonePage: 0,
    lockedVsBot: true,
    anotherTokenRemoving: false,
    startRain: false,
    privateMode: false
  }
}

let refreshMessageToday = function(state){
    let words = state.increaseWordsObjetive - state.increaseWordsToday;
    let message;
    if (state.objetiveStreak){
      message = "¡Enhorabuena, has alcanzado tu racha hoy!";
      state.messageTodaySecondary = message
    } else if (words > 1){
      message = "Sigue jugando, necesitas "+words+" palabras más para alcanzar tu racha hoy. Consigue palabras en el nivel básico, en el nivel avanzado y superando retos.";
      state.messageTodaySecondary = "Necesitas "+words+" palabras más para alcanzar tu racha hoy."
    } else if(words === 1) {
      message = "¡Sólo te queda una palabra para alcanzar tu racha hoy! Consigue palabras en el nivel básico, en el nivel avanzado y superando retos.";
      state.messageTodaySecondary = "Necesitas una palabra más para alcanzar tu racha hoy."
    } else {
      message = "Has modificado el objetivo diario, debes acertar alguna palabra más para alcanzar tu objetivo diario. Consigue palabras en el nivel básico, en el nivel avanzado y superando retos.";
      state.messageTodaySecondary = ""
    }
    state.messageToday = "Días de racha: "+state.countStreak+". "+message;
}

export default new Vuex.Store({
  state,
  mutations: {
    recalculateCaptcha (state) {
      let key = Math.floor(new Date() / 10000)
      key = state.captchaToken + key
      key = md5( key )
      state.captchaTokenResult = key
    },
    showSnackbar (state,config) {
      state.snackbarTimeout = config.timeout
      state.snackbarText = config.text
      state.snackbarType = config.type
      state.snackbarEnable = true
    },
    checkGoalStreak (state,goal) {
      if (!state.objetiveStreak && goal) {
        state.step.goalStreak = true
        state.countStreak++
        state.objetiveStreak = true
      }
      refreshMessageToday(state)
    },
    refreshMessageToday (state){
      refreshMessageToday(state)
    }
  },
  actions: {

  }
})
