<template>
  <v-progress-circular

    :size="sizeBar"
    :width="widthBar"
    :value="getPercentageToday()"
    :color="getStatusToday()"
    @click="showModal"
  >
    {{ $store.state.countStreak }}
  </v-progress-circular>
</template>

<script>

export default {
  name: 'progressToday',
  props: ['size'],
  data() {
    return {
      sizeBar: 55,
      widthBar: 8
    }
  },
  mounted: function(){
    if (this.size === 'big'){
      this.sizeBar = 80
      this.widthBar = 12
    }
    let that = this
    this.$root.$on('updateProgressToday',function(){
      that.$forceUpdate()
    })
  },
  beforeDestroy: function(){
    this.$root.$off('updateProgressToday')
  },
  methods: {
    getPercentageToday: function(){
      let percentage = Math.floor(this.$store.state.increaseWordsToday * 100 / this.$store.state.increaseWordsObjetive);
      return percentage > 100 ? 100 : percentage;
    },
    getStatusToday: function(){
      return this.$store.state.objetiveStreak ? '#4caf50' : '#fb8c00';
    },
    showModal: function(){
      let text = ''
      if (this.$store.state.countStreak == 1){
        text += 'Tienes un punto de racha. '
      } else {
        text += 'Tienes ' + this.$store.state.countStreak + ' puntos de racha. '
      }
      text += this.$store.state.messageTodaySecondary+' Puedes conseguir puntos de racha acertando palabras en la fase "escribir" de los niveles avanzado y básico, y también superando retos. Sólo puedes conseguir un punto de racha por día, y si algún día no consigues tu punto de racha perderás todos los que has conseguido previamente. Para conseguir un punto de racha debes acertar las palabras que te hayas marcado como objetivo diario en "Ajustes". Necesitarás puntos de racha para desbloquear la mayoría de los poemas. '
      this.$root.$emit('showModal', { text , title : 'Puntos de racha' } )
    }
  }
}
</script>

<style>

</style>
