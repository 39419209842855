<template>
    
<v-layout row justify-center>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title row justify-center>
        <h3 class="headline text-md-center">Cerrar sesión</h3>
      </v-card-title>
      <v-form ref="form">
        <v-card-text>
          <v-container grid-list-md>
            ¿Deseas cerrar la sesión?
          </v-container>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" text @click="dialog = false">No</v-btn>
        <v-btn color="success" :loading="loading" @click="logout">Sí</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-layout>

</template>

<script>

export default {
  name: 'logout',
  data() {
    return {
      dialog: false,
      loading: false
    }
  },
  methods: {
    show: function(){
      this.on = true
      this.dialog = true
    },
    logout: function() {
      this.loading = true;
      this.$http.post(this.$store.state.urlApi+'auth/logout', { },
        { headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '+this.$store.state.accessToken
      }}).then(function() {
        window[this.$store.state.storage].clear()
        this.dialog = false
        this.loading = false
        this.$store.state.accessToken = ''
        this.$store.state.refreshToken = ''
        this.$store.state.currentRoute = '/'
        this.$router.push(this.$store.state.currentRoute);
      }, function() {
        window[this.$store.state.storage].clear()
        this.dialog = false
        this.loading = false
        this.$store.state.accessToken = ''
        this.$store.state.refreshToken = ''
        this.$store.state.currentRoute = '/'
        this.$router.push(this.$store.state.currentRoute)
      });
    
    },
    getPercentageToday: function(){
      let percentage = Math.floor(this.$store.state.increaseWordsToday * 100 / this.$store.state.increaseWordsObjetive);
      return percentage > 100 ? 100 : percentage;
    },
    getStatusToday: function(){
      return this.getPercentageToday() === 100 ? '#4caf50' : '#fb8c00';
    }
  }
}
</script>

<style>

</style>
