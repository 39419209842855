<template>
  <v-btn block rounded color="primary" :loading="loading" @click="login()">¡Empezar ya!</v-btn>
</template>
<script>

export default {
  name: 'AnonymousLogin',
  data() {
    return {
      loading: false
    }
  },
  methods: {
    login: function(){
      if (this.$store.state.accessToken){
        this.$store.state.refreshHome = true
        this.$store.state.currentRoute = '/home'
        this.$router.push('/home')
        return
      }
      this.loading = true
      this.$store.commit('recalculateCaptcha')
      this.$http.post(this.$store.state.urlApi+'auth/login/anonymous', { },
        { headers: {
          Accept: 'application/json',
          'Captcha-Authorization': this.$store.state.captchaTokenResult
      }}).then(response => {
        this.$store.state.accessToken = response.body.access_token;
        this.$store.state.refreshToken = response.body.refresh_token;
        this.$store.state.expireAt = new Date().getTime() / 1000 + response.body.expires_in
        this.$store.state.refreshExpireAt = new Date().getTime() / 1000 + response.body.refresh_expires_in
        this.$store.state.anotherTokenRemoving = response.body.another_token_removing
        this.loading = false
        this.$store.state.refreshHome = true
        this.$store.state.anonymous = true
        this.$store.state.currentRoute = '/home'
        this.$router.push(this.$store.state.currentRoute)
      }, function() {
        this.$store.commit('showSnackbar',{
          timeout : 6000,
          text : 'La plataforma no está disponible en este momento, inténtelo más tarde',
          type : 'warning'
        })
        this.loading = false
      })
    }
  }
}
</script>

<style>

</style>
