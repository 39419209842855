<template>
	<div>
		<bar title="true"/>

		<v-content>
			<message/>
			<v-container>
				<v-row no-gutters align="center" justify="center">
					<v-col xs="12" class="text-center">
						<v-card
							shaped
							flat
							dark
							outlined
							class="pa-2"
						>
							<h1 class="text-center headline font-weight-black">Aprende inglés escuchando poemas</h1>
							<v-avatar size="150" class="mt-2">
								<img src="image.jpg" />
							</v-avatar>
							<v-row align="center" justify="center">
								<v-col md="4" sm="6" xs="12">
									<anonymous-login/>
									<login/>
								</v-col>
							</v-row>
						</v-card>
					</v-col>
				</v-row>
				<!---------------------------------------------------------------------------------------------->
				<v-row no-gutters align="center" justify="center" class="mt-4" v-if="$store.state.privateMode === false">
					<v-col cols="12" class="text-center">
						<v-card
							shaped
							outlined
							flat
							light
						>
						<h2 class="text-center title font-weight-black">Aprende online, totalmente gratis, con poemas clásicos de la literatura anglosajona</h2>

						<v-container grid-list-md>

							<v-layout row wrap>
								<v-flex xs2 class="text-left">
									<v-icon large>mdi-translate</v-icon>
								</v-flex>

								<v-flex xs10>
									<p class="text-left font-weight-thin">¿Estás harto de aprender inglés con frases estúpidas cómo "El coche corre rápido"? ¿Eres capaz de leer en inglés pero llevas peor comprender lo que escuchas?</p>
								</v-flex>
							</v-layout>

							<v-layout row wrap>
								<v-flex xs2 class="text-left">
									<v-icon large>mdi-headset</v-icon>
								</v-flex>

								<v-flex xs10>
									<p class="text-left font-weight-thin">En poemsandlearn aprenderás con poemas clásicos de la literatura anglosajona que te sumergirán en su cultura y te contarán bonitas historias. Además de ejercicios de vocabulario, trabajarás el oído escuchando primero los poemas con subtítulos y luego sin ellos, hasta que reconozcas lo que escuchas.</p>
								</v-flex>
							</v-layout>

							<v-layout row wrap>
								<v-flex xs2 class="text-left">
									<v-icon large>mdi-fingerprint</v-icon>
								</v-flex>

								<v-flex xs10>
									<p class="text-left font-weight-thin">Cada poema tiene dos niveles, primero superarás el nivel básico, con el que aprenderás verso a verso el vocabulario, el significado del poema y a reconocerlo escuchándolo, en el nivel avanzado consolidarás lo aprendido escuchando el poema entero y centrando el aprendizaje en reconocer sin subtítulos las palabras y frases que escuches.</p>
								</v-flex>
							</v-layout>

							<v-layout row wrap>
								<v-flex xs2 class="text-left">
									<v-icon large>mdi-school</v-icon>
								</v-flex>

								<v-flex xs10>
									<p class="text-left font-weight-thin">Puedes marcarte un objetivo diario y poemsandlearn te avisará cuando lo superes.</p>
								</v-flex>
							</v-layout>

							</v-container>

						</v-card>
					</v-col>
				</v-row>


				<v-row no-gutters align="center" justify="center" v-if="poems.length > 0">
					<v-col cols="12" class="text-center mt-3">
						<h3>Algunos de los poemas con los que aprenderás</h3>
					</v-col>
				</v-row>
				<v-row no-gutters align="center" justify="center" v-if="poems.length > 0">
					<v-col md="6" sm="6" xs="12" v-for="poem in poems" :key="poem.id" class="text-center">
						<v-avatar size="140" class="mt-2">
							<img :src="'https://poemsandlearn.delavieja.com'+poem.link+'/image.jpg'" />
						</v-avatar>
						<div>
							<div class="title font-weight-thin">{{poem.title}} de {{poem.author}}</div>
						</div>
					</v-col>
				</v-row>
				<v-row no-gutters align="center" justify="center" v-if="poems.length > 0">
					<v-col xs="12" class="text-center">
						<v-row align="center" justify="center">
							<v-col md="4" sm="6" xs="12" class="mt-3">
								<anonymous-login/>
							</v-col>
						</v-row>
					</v-col>
				</v-row>

			</v-container>
		</v-content>

		<private-modal v-if="$store.state.privateMode === true"/>

		<pl-footer/>
	</div>
</template>

<script>

import bar from '../components/appComponents/Bar.vue'
import message from '../components/appComponents/Message.vue'
import footer from '../components/appComponents/Footer.vue'
import anonymousLogin from '../components/launchers/AnonymousLogin.vue'
import login from '../components/launchers/Login.vue'
import privateModal from '../components/modals/PrivateModal.vue'

export default {
  name: 'start',
  components: {
	'pl-footer': footer,
	'anonymous-login' : anonymousLogin,
	message,
	bar,
	login,
	'private-modal' : privateModal
  },
  data: () => ({
    poems: [],
    serializedData: '%serializedData%'
  }),
  beforeMount(){
  	this.$store.state.accessToken = ''
    this.$store.state.messageView = false
    this.$store.state.toStartRequest = false
	try {
		this.poems = JSON.parse(this.serializedData)
	} catch(err){
		this.poems = []
	}
  },
  mounted(){
	  if (this.$store.state.privateMode === true){
		  this.$root.$emit('showPrivateModal')
	  }
  }
}
</script>
